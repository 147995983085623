<template>





    <div  v-show="!$vuetify.breakpoint.smAndDown && tasks.length">
    <v-card-title class="headline text-h6"  >
        <span class="font-weight-light">Buenas Tardes, </span> <span class="font-weight-bold">
          {{ $store.state.Auth.token.claims.name.split(" ")[0] }}
        </span>
       
        <v-spacer/>

        <v-btn small outlined v-if="!$vuetify.breakpoint.smAndDown && tasks.length" @click="$router.push('/tareas')">
        <v-icon left>
          mdi-order-bool-ascending-variant
        </v-icon>
          Ver pendientes
        </v-btn>

      </v-card-title>
      <v-divider/>
      <v-card-text>

     









          <v-row>
   
        <template v-if="!$vuetify.breakpoint.smAndDown">
        <v-col cols="12" md="3" v-for="(task,index) in tasks">
            <v-list-item three-line>
                 <v-list-item-avatar>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="markAsDone(task)"
                            :disabled="task.done"
                            title="Marcar como completada"
                            >
                            <v-icon>mdi-circle-outline </v-icon>
                            </v-btn>
                        </template>
                        <span>Marcar como completada</span>
                    </v-tooltip>




            
                  </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title style="white-space:unset">
                        {{ task.title }}
                        
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getRelativeDueDate(task.dueDate) }}
                        <v-icon
                          v-if="isOverdue(task)"
                          color="red"
                          small
                          class="ml-1"
                          title="Tarea vencida"
                        >
                          mdi-alert-circle
                        </v-icon>

                     
                      </v-list-item-subtitle>
                        <v-list-item-subtitle>
                              <v-chip 
                          :color="getPriorityColor(task.priority)"
                          x-small
                        >
                          {{ task.priority }}
                        </v-chip>
                            </v-list-item-subtitle>
                </v-list-item-content>
               
            </v-list-item>


        </v-col>
        </template> 
        
    </v-row>
      </v-card-text>
    </div>

</template>

<script>
// Importar Moment.js y su configuración en español
import moment from 'moment';
import 'moment/locale/es';

import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  limit
} from "firebase/firestore";

export default {
  name: "NotDoneTasksList",
  data() {
    return {
      tasks: [],
      loading: true,
      priorityLevels: ["Alta", "Media", "Baja"],
      dueDateMenu: false, // Si usas dueDateMenu en el formulario
      // ... otros datos
    };
  },
  created() {
    // Configurar Moment.js a español
    moment.locale('es');
  },
  methods: {
    /**
     * Retorna un color basado en la prioridad de la tarea.
     */
    getPriorityColor(priority) {
      switch (priority) {
        case "Alta":
          return "red";
        case "Media":
          return "orange";
        case "Baja":
          return "green";
        default:
          return "grey";
      }
    },

    /**
     * Formatea la fecha de vencimiento usando Moment.js para mostrar tiempo relativo en español.
     */
    getRelativeDueDate(date) {
      if (!date) return "Sin vencimiento";
      const dueDate = date.toDate ? date.toDate() : new Date(date);
      const today = moment().startOf('day');
      const taskDate = moment(dueDate).startOf('day');

      const diffDays = taskDate.diff(today, 'days');

      if (diffDays < 0) {
        return `Vencida hace ${Math.abs(diffDays)} día${Math.abs(diffDays) > 1 ? 's' : ''}`;
      } else if (diffDays === 0) {
        return "Vence hoy";
      } else {
        return `En ${diffDays} día${diffDays > 1 ? 's' : ''}`;
      }
    },

    /**
     * Verifica si una tarea está vencida.
     */
    isOverdue(task) {
      if (!task.dueDate || task.done) return false;
      const dueDate = task.dueDate.toDate ? task.dueDate.toDate() : new Date(task.dueDate);
      const now = moment().startOf('day');
      return moment(dueDate).isBefore(now, 'day');
    },

    /**
     * Retorna una clase CSS basada en el estado de la fecha de vencimiento de la tarea.
     */
    getTaskStatusClass(task) {
      if (!task.dueDate) {
        return 'no-due-date-task';
      }

      if (this.isOverdue(task)) {
        return 'overdue-task';
      }

      const dueDateStatus = this.getRelativeDueDate(task.dueDate);
      if (dueDateStatus === "Vence hoy") {
        return 'due-today-task';
      }
      return 'upcoming-task';
    },

    /**
     * Marca una tarea como completada en Firestore.
     */
    async markAsDone(task) {
      const db = getFirestore();
      const taskRef = doc(db, "tasks", task.id);
      try {
        await updateDoc(taskRef, {
          done: true,
          completedBy: this.$store.state.Auth.token.claims.user_id,
        });

        this.$notify({
          title: "Tarea completada",
          text: "La tarea se ha marcado como completada.",
          type: "success",
        });
      } catch (error) {
        console.error("Error updating task: ", error);
        this.$notify({
          title: "Error",
          text: "No se pudo marcar la tarea como completada.",
          type: "error",
        });
      }
    },
  },
  mounted() {
    const db = getFirestore();
    const tasksCollection = collection(db, "tasks");

    // Definir la consulta: no completadas, ordenadas por fecha de creación descendente y prioridad ascendente
    const tasksQuery = query(
      tasksCollection,
      where("done", "==", false),
      orderBy("date", "desc"),
      orderBy("priority", "asc"),
      limit(5)
    );

    // Escuchar actualizaciones en tiempo real
    this.unsubscribe = onSnapshot(
      tasksQuery,
      (querySnapshot) => {
        this.tasks = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          //if (data.dueDate) {
             this.tasks.push({
            id: doc.id,
            ...data,
          });
          //}
         
        });

        // Order by dueDate and priority (Alta, Media, Baja)
        this.tasks.sort((a, b) => {
          const priorityOrder = { "Alta": 1, "Media": 2, "Baja": 3 };
          const dueDateA = a.dueDate ? new Date(a.dueDate.toDate ? a.dueDate.toDate() : a.dueDate) : null;
          const dueDateB = b.dueDate ? new Date(b.dueDate.toDate ? b.dueDate.toDate() : b.dueDate) : null;

          if (dueDateA && dueDateB) {
            if (dueDateA < dueDateB) return -1;
            if (dueDateA > dueDateB) return 1;
          } else if (dueDateA) {
            return -1;
          } else if (dueDateB) {
            return 1;
          }

          return priorityOrder[a.priority] - priorityOrder[b.priority];
        });
        


        
        

        

        this.loading = false;
      },
      (error) => {
        console.error("Error fetching tasks: ", error);
        this.loading = false;
        this.$notify({
          title: "Error",
          text: "No se pudieron cargar las tareas pendientes.",
          type: "error",
        });
      }
    );
  },
  beforeDestroy() {
    // Cancelar la suscripción para prevenir fugas de memoria
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style scoped>
.task-list {
  max-height: 300px;
  overflow-y: auto;
}

/* Tarea sin Fecha de Vencimiento */
.no-due-date-task {
  background-color: rgba(128, 128, 128, 0.1); /* Gris claro */
}

</style>
