<template>
  <v-container v-bind:class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-card class="glass">
      <Pending />

      <v-toolbar class="glass">
        <v-icon left>mdi-history</v-icon>
        <v-toolbar-title class="text-h6 font-weight-300">Actividad</v-toolbar-title>
        <v-spacer></v-spacer>
        <span>
          <v-select
            class="rounded-lg"
            v-bind:style="{'max-width': !$vuetify.breakpoint.mdAndUp ? '150px' : 'unset'}"
            single-line
            v-model="selectedPeriod"
            :items="periodOptions"
            label="Selecciona un periodo"
            @change="onPeriodChange"
            hide-details
            rounded
            filled
            dense
          ></v-select>
        </span>
      </v-toolbar>
      <v-divider v-if="selectedPeriod === 'Periodo personalizado'" />
      <v-toolbar flat v-if="selectedPeriod === 'Periodo personalizado'">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRangeText"
              placeholder="Selecciona un rango de fechas"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              hide-details
              filled
              rounded
              single-line
              class="rounded-lg"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateRange"
            range
            @change="onDateRangeChange"
            locale="es"
          ></v-date-picker>
        </v-menu>
      </v-toolbar>

      <v-card-text class="pa-0" >
        <v-fade-transition hide-on-leave>
          <!-- Loading Skeleton -->
          <v-skeleton-loader type="list-item-two-line, list-item-two-line" v-if="loading"></v-skeleton-loader>
        </v-fade-transition>

        <v-fade-transition hide-on-leave >
          <!-- Activity List -->
          <v-expansion-panels v-if="!loading && workouts.length"  class="elevation-0" >
            <v-expansion-panel
              v-for="(workout, index) in workouts"
              :key="index"
              class="elevation-0"
              
              
            >
              <v-expansion-panel-header class="elevation-0">
                <v-avatar size="40">
                  <UserImage :user="{ id: workout.userId }" xsmall />
                </v-avatar>
                <div class="ml-3">
                  <div class="font-weight-medium">{{ workout.user_name }}</div>
                  <div class="grey--text">{{ formatDate(workout.date) }}</div>

                   <v-chip :color="workout.plan_color" dark small label v-show="!$vuetify.breakpoint.mdAndUp">
                  {{ workout.plan_name }} | Semana {{ workout.week }} | Día {{ workout.workoutDays + 1 }}
                </v-chip>
                </div>
                <v-spacer />
                <v-chip :color="workout.plan_color" dark small label v-show="$vuetify.breakpoint.mdAndUp">
                  {{ workout.plan_name }} | Semana {{ workout.week }} | Día {{ workout.workoutDays + 1 }}
                </v-chip>
                <template v-if="!$vuetify.breakpoint.smAndDown">
                  <v-spacer />
                  <v-icon color="yellow">
                    {{ workout.rating >= 5 ? 'mdi-star' : 'mdi-star-half-full' }}
                  </v-icon>
                  {{ workout.rating }}
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="elevation-0">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" v-if="workout.day_exercises">
                      <p v-show="$vuetify.breakpoint.smAndDown">
                        <strong>Calificación: </strong>
                        <v-icon color="yellow">
                          {{ workout.rating >= 5 ? 'mdi-star' : 'mdi-star-half-full' }}
                        </v-icon>
                        {{ workout.rating }}
                      </p>
                      <strong>Ejercicios:</strong>
                      <Simple :exercises="workout.day_exercises" />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="workout.comment">
                      <p>
                        <strong>Comentario: </strong>
                        <v-alert text icon="mdi-comment">{{ workout.comment }}</v-alert>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-fade-transition>

        <v-scroll-x-transition hide-on-leave>
          <v-alert v-if="!loading && !workouts.length" type="info" text class="mx-4">
            No hay actividad para mostrar.
          </v-alert>
        </v-scroll-x-transition>
      </v-card-text>
    </v-card>
    <v-card class="glass" outlined></v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import UserImage from '@/components/profile/UserImage.vue';
import Simple from '@/components/planifications/view/Simple.vue';
import Pending from '@/components/tasks/Pending.vue';

export default {
  components: {
    UserImage,
    Simple,
    Pending,
  },
  name: 'Actividad',
  data() {
    return {
      loading: true,
      selectedPeriod: 'Ultima semana',
      periodOptions: ['Ultima semana', 'Ultimo mes', 'Periodo personalizado'],
      menu: false,
      dateRange: [],
      dateRangeText: '',
      workouts: [],
      currentUserId: 'CURRENT_USER_ID', // Replace with actual current user ID
      unsubscribes: [],
      workoutsMap: {},
    };
  },
  watch: {
    selectedPeriod() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.fetchWorkouts();
      }
    },
    dateRange: {
      handler(newVal) {
        if (newVal.length === 2) {
          this.fetchWorkouts();
        }
      },
      deep: true,
    },
  },
  methods: {
    onPeriodChange() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },
    onDateRangeChange() {
      this.menu = false;
    },
    formatDate(date) {
      return moment(date.toDate()).locale('es').format('LL'); // e.g., 20 de abril de 2024
    },
    async fetchWorkouts() {
      // Clean up previous listeners
      this.unsubscribes.forEach((unsubscribe) => unsubscribe());
      this.unsubscribes = [];
      this.workoutsMap = {};
      this.workouts = [];
      this.loading = true;

      const db = getFirestore();
      let startDate, endDate;

      if (this.selectedPeriod === 'Ultima semana') {
        startDate = moment().subtract(7, 'days').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Ultimo mes') {
        startDate = moment().subtract(1, 'months').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Periodo personalizado' && this.dateRange.length === 2) {
        startDate = moment(this.dateRange[0]).startOf('day').toDate();
        endDate = moment(this.dateRange[1]).endOf('day').toDate();
      } else {
        startDate = null;
        endDate = null;
      }

      try {
        const workoutsCollection = collection(db, 'workouts');
        let workoutsQuery;

        if (startDate && endDate) {
          workoutsQuery = query(
            workoutsCollection,
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            orderBy('date', 'desc')
          );
        } else {
          workoutsQuery = query(workoutsCollection, orderBy('date', 'desc'));
        }

        const unsubscribe = onSnapshot(
          workoutsQuery,
          (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              const workoutId = change.doc.id;
              const workoutData = change.doc.data();

              if (change.type === 'added' || change.type === 'modified') {
                // Add or update the workout in workoutsMap
                this.$set(this.workoutsMap, workoutId, {
                  id: workoutId,
                  ...workoutData,
                });
              } else if (change.type === 'removed') {
                // Remove the workout from workoutsMap
                this.$delete(this.workoutsMap, workoutId);
              }
            });
            // Update workouts array
            this.workouts = Object.values(this.workoutsMap);
            // Sort workouts by date (most recent first)
            this.workouts.sort((a, b) => b.date.toMillis() - a.date.toMillis());
            this.loading = false;
          },
          (error) => {
            console.error('Error listening to workouts:', error);
            this.loading = false;
          }
        );

        this.unsubscribes.push(unsubscribe);

        if (this.workouts.length === 0) {
          // If no workouts are found, stop loading
          this.loading = false;
        }
      } catch (error) {
        console.error('Error fetching workouts:', error);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchWorkouts();
  },
  beforeDestroy() {
    // Clean up listeners
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
    this.unsubscribes = [];
  },
};
</script>
